<template>
  <div class="bg-grey-lightest rounded-medium py-10 px-4 lg:px-7 xl:py-14">
    <div class="grid grid-cols-1 2xl:grid-cols-12 gap-4">
      <div class="hidden 2xl:block col-start-1 col-span-3 flex flex-col items-center justify-center">
        <h2 class="sub-title text-base text-2xl md:text-4xl">Cost Savings</h2>
        <img src="@/assets/images/result/costSaving.png" alt="">
      </div>

      <div class="2xl:col-start-4 2xl:col-end-13">
        <h2 class="sub-title text-base text-2xl md:text-4xl 2xl:hidden">
          Cost Savings
        </h2>

        <div class="rounded-lg shadow-lg">
          <div class="grid grid-cols-1 xl:grid-cols-12 xl:gap-6">
            <div class="bg-white xl:col-span-8 p-4 xl:p-7 rounded-tl-lg rounded-bl-lg">
              <div class="fields sm:flex justify-between">
                <form-field-select
                    v-model="selected"
                    class="lg:w-2/5"
                    track-by="id"
                    option-label="name"
                    disabled-option-value="Select Phase"
                    field-label="Select the phase:"
                    :options="options"
                    @change="onSetPhase"
                />

                <form-field-select
                    v-model="currency"
                    class="lg:w-2/8"
                    track-by="code"
                    option-label="code"
                    field-label="Currency:"
                    :options="currencies"
                    @change="onSetCurrency"
                />
              </div>

              <div
                v-if="calculateReplacement.length"
                class="mt-3 bg-red-300 border border-red-600 rounded-xl p-3 md:py-3 md:px-5 font-medium text-sm text-red-600"
              >
                <span
                  class="icon md:mr-3.5"
                  v-html="icons.info"
                ></span>

                {{ calculateReplacement }}
              </div>

              <div class="overflow-x-auto">
                <div class="align-middle inline-block min-w-full">
                  <div class="overflow-hidden">
                    <table class="min-w-full text-center leading-none text-sm divide-y divide-grey-mediumLight">
                      <thead class="uppercase text-lg text-baseColor whitespace-nowrap">
                        <tr>
                          <th class="py-4 px-5 text-left">Ingredient</th>

                          <th class="py-4 px-5">
                            Price ({{ currencySymbol }}/MT)

                            <span
                              class="icon w-4"
                              v-html="icons.question"
                              v-tooltip="'These values will be used for the calculation of cost savings'">
                            </span>
                          </th>

                          <th class="py-4 px-5">
                            Inclusion

                            <small v-if="getSelectedProduct === 'proact360'" class="block text-xs">withOUT <span class="font-bold">ProAct 360<sup>TM</sup></span></small>
                            <small v-else class="block text-xs">withOUT <span class="font-bold">ProAct</span></small>
                          </th>

                          <th class="py-4 px-5">
                            Inclusion

                            <span
                              class="icon w-4"
                              v-html="icons.question"
                              v-tooltip="'These values will be used for the calculation of cost savings'">
                            </span>

                            <small v-if="getSelectedProduct === 'proact360'" class="block text-xs text-brand-semiMedium">
                              optimised with <span class="font-bold">ProAct 360<sup>TM</sup></span>
                            </small>
                            <small v-else class="block text-xs text-brand-semiMedium">
                              optimised with <span class="font-bold">ProAct</span>
                            </small>
                          </th>
                        </tr>
                      </thead>

                      <tbody class="font-medium divide-y divide-grey-mediumLight">
                        <tr
                          v-for="ingredient in ingredientsWithPercentage"
                          :key="ingredient.id"
                        >
                          <td class="py-4 px-5 text-left text-brand-dark text-base">
                            {{ingredient.name}}
                          </td>

                          <td class="py-4 px-5">
                            <form-field-input
                              v-model="ingredient.price"
                              class="w-32"
                              type="number"
                              @input="onChangeIngredientPrice($event, ingredient)"
                            >
                              <template v-slot:icon>{{ currencySymbol }}/MT</template>
                            </form-field-input>
                          </td>

                          <td class="py-4 px-5">
                            {{ingredient.percentage}}%
                          </td>

                          <td class="py-4 px-5">
                            {{ingredient.withProact360}}%
                          </td>
                        </tr>
                      </tbody>

                      <tfoot class="border-t border-grey-mediumLight border-t-1 text-center font-medium">
                        <tr class="text-brand-semiDark">
                          <td class="py-1 px-5 text-left uppercase text-base">
                            TOTAL %
                          </td>
                          <td class="py-1 px-5"></td>
                          <td class="py-1 px-5">{{ withoutProact360Sum }}%</td>
                          <td class="py-1 px-5">{{ withProact360Sum }}%</td>
                        </tr>
                        <tr class="bg-brand-semiMedium align-centre text-white">
                          <td class="py-3 px-5 text-left uppercase text-base">
                            Feed Cost
                          </td>
                          <td class="py-3 px-5"></td>
                          <td class="py-3 px-5">{{ totalCostWithoutProact }} {{ currencySymbol }}/MT</td>
                          <td class="py-3 px-5">{{ totalCostWithProact }} {{ currencySymbol }}/MT</td>
                        </tr>
                        <tr class="bg-brand-lightest">
                          <td class="py-4 px-5 text-brand-dark text-left rounded-bl-md text-base">
                            <span v-if="getSelectedProduct === 'proact360'" class="font-bold">ProAct 360<sup>TM</sup></span>
                            <span v-else class="font-bold">ProAct</span>
                          </td>

                          <td class="py-4 px-5">
                            <form-field-input
                              v-model="price"
                              class="w-32"
                              @input="onChangeIngredientPrice"
                            >
                              <template v-slot:icon>{{ currencySymbol }}/Kg</template>
                            </form-field-input>
                          </td>

                          <td></td>

                          <td class="py-4 px-5 rounded-br-md">
                            <form-field-input
                              disabled
                              :value="activePhase.dose"
                            >
                              <template v-slot:icon>g/MT</template>
                            </form-field-input>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="xl:-ml-6 xl:col-span-4 flex flex-col xl:justify-center px-4 py-6 xl:px-4 xl:px-10 bg-blue-light bg-opacity-50">
              <div class="bg-brand-semiMedium py-10 px-5 rounded-xl flex items-center space-x-4 text-white">
                <div class="flex-shrink-0">
                  <div class="bg-white ring-4 ring-white ring-opacity-20 rounded-lg p-1.5 text-brand-semiMedium">
                    <svg class="w-8 h-8" viewBox="0 0 32 29" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.11414 18.7126C5.99948 19.4615 7.0035 20.0554 8.07951 20.5017L13.7383 17.1415C14.909
                            16.443 16.477 16.6082 17.457 17.54L19.3597 19.3392C22.2117 17.3508 23.9998 14.1697 23.9998
                            10.7174C23.9998 4.8077 18.9157 0 12.6663 0C6.41682 0 1.33275 4.8077 1.33275 10.7174C1.33275
                            13.7674 2.7101 16.6813 5.11414 18.7126ZM13.1729 11.663H12.1596C10.6009 11.663 9.33287 10.464
                            9.33287 8.99C9.33287 7.66483 10.3435 6.57417 11.6662 6.35226V5.3587C11.6662 4.8367 12.1142
                            4.41304 12.6663 4.41304C13.2183 4.41304 13.6663 4.8367 13.6663 5.3587V6.30435H14.9996C15.5516
                            6.30435 15.9996 6.728 15.9996 7.25C15.9996 7.772 15.5516 8.19565 14.9996 8.19565H12.1596C11.7036
                            8.19565 11.3329 8.54617 11.3329 8.97739C11.3329 9.42122 11.7036 9.77174 12.1596 9.77174H13.1729C14.7316
                            9.77174 15.9996 10.9708 15.9996 12.4448C15.9996 13.77 14.989 14.8606 13.6663 15.0825V16.0748C13.6663
                            16.5968 13.2183 17.0205 12.6663 17.0205C12.1142 17.0205 11.6662 16.5968 11.6662 16.0748V15.1304H10.3329C9.78087
                            15.1304 9.33287 14.7068 9.33287 14.1848C9.33287 13.6628 9.78087 13.2391 10.3329 13.2391H13.1729C13.6289 13.2391
                            13.9996 12.8886 13.9996 12.4574C13.9996 12.0136 13.6289 11.663 13.1729 11.663Z"
                      />
                      <path d="M30.6665 13.8696H25.3331C24.7944 13.8696 24.3064 14.1772 24.1011 14.6475C23.8944 15.1191 24.0091
                            15.6613 24.3904 16.0219L26.1145 17.6522L20.6664 22.8041L16.2756 18.652C15.8396 18.2372 15.1543 18.1653
                            14.6316 18.4717L0.631402 26.6674C0.00472619 27.0343 -0.187277 27.811 0.199396 28.4023C0.4514 28.7882
                            0.887406 29 1.33408 29C1.57408 29 1.81542 28.9395 2.03409 28.8109L15.1356 21.141L19.7224 25.4784C20.2437
                             25.9714 21.0864 25.9714 21.6077 25.4784L27.9998 19.435L29.7238 21.0653C29.9785 21.3062 30.3199 21.4348
                             30.6665 21.4348C30.8385 21.4348 31.0119 21.4033 31.1772 21.339C31.6759 21.1435 31.9999 20.6833 31.9999
                             20.1739V15.1304C31.9999 14.4344 31.4039 13.8696 30.6665 13.8696Z"
                      />
                    </svg>
                  </div>
                </div>

                <div>
                  <div class="text-base uppercase font-medium">cost savings</div>
                  <p class="text-2xl font-semibold">{{costSaving}} {{ currencySymbol }}/MT</p>
                </div>
              </div>

              <div class="my-6">
                <form-field-input
                  v-model="annualCostSaving"
                  size="medium"
                  type="text"
                  field-label="Annual Feed Production"
                  :isRemoveZero="true"
                  :isNeedFormatter="true"
                >
                  <template v-slot:icon>(MT)</template>
                </form-field-input>
              </div>

              <div class="bg-brand-dark py-10 px-5 rounded-xl flex items-center space-x-4 text-white">
                <div class="flex-shrink-0">
                  <div class="bg-white ring-4 ring-white ring-opacity-20 rounded-lg p-1.5 text-brand-dark">
                    <svg class="w-8 h-8" viewBox="0 0 36 29" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.8301 20.2759C18.4205 20.2759 22.9681 15.7283 22.9681 10.138C22.9681 4.54757 18.4205 0
                            12.8301 0C7.23971 0 2.69214 4.54757 2.69214 10.138C2.69214 15.7283 7.23971 20.2759 12.8301
                            20.2759ZM13.168 10.8138H12.4921C11.8945 10.8153 11.3186 10.59 10.8806 10.1833C10.4426 9.77674
                            10.1752 9.21912 10.1324 8.62304C10.0895 8.02695 10.2743 7.4368 10.6496 6.97168C11.0248 6.50656
                            11.5625 6.20112 12.1542 6.11699V5.40689C12.1542 5.22764 12.2254 5.05572 12.3522 4.92897C12.4789
                            4.80222 12.6508 4.73101 12.8301 4.73101C13.0094 4.73101 13.1813 4.80222 13.308 4.92897C13.4348
                            5.05572 13.506 5.22764 13.506 5.40689V6.08278H14.8577C15.0369 6.08278 15.2089 6.15399 15.3356
                            6.28074C15.4624 6.40749 15.5336 6.57941 15.5336 6.75866C15.5336 6.93792 15.4624 7.10983 15.3356
                            7.23658C15.2089 7.36334 15.0369 7.43455 14.8577 7.43455H12.4922C12.2233 7.43455 11.9655 7.54136
                            11.7753 7.73148C11.5852 7.9216 11.4784 8.17947 11.4784 8.44834C11.4784 8.71722 11.5852 8.97508
                            11.7753 9.16521C11.9655 9.35533 12.2233 9.46214 12.4922 9.46214H13.1681C13.7657 9.4607 14.3416
                            9.68603 14.7796 10.0926C15.2175 10.4993 15.485 11.0569 15.5278 11.6529C15.5707 12.249 15.3859
                            12.8392 15.0106 13.3043C14.6354 13.7694 14.0977 14.0749 13.506 14.159V14.869C13.506 15.0483
                            13.4348 15.2202 13.308 15.347C13.1813 15.4737 13.0094 15.5449 12.8301 15.5449C12.6508 15.5449
                            12.4789 15.4737 12.3522 15.347C12.2254 15.2202 12.1542 15.0483 12.1542 14.869V14.1931H10.8025C10.6233
                            14.1931 10.4513 14.1219 10.3246 13.9952C10.1978 13.8684 10.1266 13.6965 10.1266 13.5173C10.1266 13.338
                            10.1978 13.1661 10.3246 13.0393C10.4513 12.9126 10.6233 12.8414 10.8025 12.8414H13.168C13.4369 12.8414
                            13.6947 12.7346 13.8849 12.5444C14.075 12.3543 14.1818 12.0965 14.1818 11.8276C14.1818 11.5587 14.075
                            11.3008 13.8849 11.1107C13.6947 10.9206 13.4369 10.8138 13.168 10.8138V10.8138Z"
                      />
                      <path
                        d="M27.6976 10.138C28.5579 10.138 29.3988 9.88288 30.1141 9.40495C30.8294 8.92702 31.3869 8.24772
                            31.7161 7.45295C32.0453 6.65818 32.1314 5.78364 31.9636 4.93992C31.7957 4.0962 31.3815 3.3212
                            30.7732 2.71291C30.1649 2.10462 29.3899 1.69037 28.5462 1.52254C27.7025 1.35471 26.8279 1.44085 26.0332
                            1.77005C25.2384 2.09926 24.5591 2.65674 24.0812 3.37201C23.6032 4.08729 23.3481 4.92822 23.3481
                            5.78847C23.3494 6.94164 23.8081 8.04722 24.6235 8.86264C25.4389 9.67806 26.5445 10.1367 27.6976
                            10.138ZM27.8426 6.07841H27.5527C27.2965 6.07871 27.0497 5.98189 26.8621 5.80748C26.6745 5.63306
                            26.5599 5.39401 26.5415 5.13849C26.5231 4.88297 26.6023 4.62998 26.763 4.4305C26.9238 4.23102
                            27.1541 4.09988 27.4077 4.06349V3.75872C27.4077 3.68182 27.4383 3.60806 27.4926 3.55368C27.547
                            3.4993 27.6208 3.46874 27.6977 3.46874C27.7746 3.46874 27.8483 3.4993 27.9027 3.55368C27.9571
                            3.60806 27.9877 3.68182 27.9877 3.75872V4.04867H28.5676C28.6445 4.04867 28.7183 4.07922 28.7727
                            4.1336C28.827 4.18798 28.8576 4.26174 28.8576 4.33865C28.8576 4.41555 28.827 4.48931 28.7727
                            4.54369C28.7183 4.59807 28.6445 4.62862 28.5676 4.62862H27.5527C27.4382 4.63 27.3289 4.67644
                            27.2485 4.75786C27.1681 4.83927 27.1229 4.94912 27.1229 5.06357C27.1229 5.17803 27.1681 5.28787
                            27.2485 5.36929C27.3289 5.45071 27.4382 5.49714 27.5527 5.49852H27.8426C28.0988 5.49823 28.3456
                            5.59504 28.5332 5.76946C28.7208 5.94388 28.8354 6.18293 28.8538 6.43845C28.8721 6.69396 28.793
                            6.94695 28.6323 7.14643C28.4715 7.34591 28.2412 7.47706 27.9876 7.51344V7.81809C27.9876 7.895 27.957
                            7.96875 27.9027 8.02313C27.8483 8.07751 27.7745 8.10807 27.6976 8.10807C27.6207 8.10807 27.547 8.07751
                            27.4926 8.02313C27.4382 7.96875 27.4076 7.895 27.4076 7.81809V7.52808H26.8277C26.7508 7.52808 26.677
                            7.49753 26.6226 7.44315C26.5683 7.38877 26.5377 7.31501 26.5377 7.2381C26.5377 7.1612 26.5683 7.08744
                            26.6226 7.03306C26.677 6.97868 26.7508 6.94812 26.8277 6.94812H27.8426C27.9579 6.94812 28.0685 6.9023
                            28.1501 6.82073C28.2317 6.73916 28.2775 6.62853 28.2775 6.51317C28.2775 6.39782 28.2317 6.28719 28.1501
                            6.20562C28.0685 6.12405 27.9579 6.07822 27.8426 6.07822L27.8426 6.07841Z"
                      />
                      <path
                        d="M3.79991 25.9377C4.54131 25.9377 5.26607 25.7179 5.88253 25.306C6.49899 24.894 6.97946
                            24.3086 7.26319 23.6236C7.54692 22.9387 7.62116 22.1849 7.47652 21.4578C7.33188 20.7306
                            6.97487 20.0627 6.45062 19.5384C5.92637 19.0141 5.25843 18.6571 4.53127 18.5125C3.80411
                            18.3678 3.05038 18.442 2.36541 18.7258C1.68043 19.0095 1.09497 19.4899 0.68306 20.1064C0.271147
                            20.7228 0.0512821 21.4476 0.0512695 22.189C0.0523429 23.1829 0.44763 24.1357 1.1504 24.8385C1.85317
                            25.5413 2.80603 25.9366 3.79991 25.9377ZM3.92486 22.439H3.67496C3.45403 22.4394 3.24116 22.356 3.07929
                            22.2057C2.91741 22.0553 2.81858 21.8492 2.80272 21.6288C2.78687 21.4085 2.85518 21.1903 2.99386
                            21.0184C3.13255 20.8464 3.33129 20.7334 3.55001 20.7022V20.4396C3.55001 20.3734 3.57634 20.3098
                            3.62321 20.2629C3.67008 20.216 3.73365 20.1897 3.79994 20.1897C3.86622 20.1897 3.9298 20.216 3.97667
                            20.2629C4.02354 20.3098 4.04987 20.3734 4.04987 20.4396V20.6897H4.54967C4.61596 20.6897 4.67953 20.7161
                            4.7264 20.7629C4.77327 20.8098 4.7996 20.8734 4.7996 20.9397C4.7996 21.0059 4.77327 21.0695 4.7264
                            21.1164C4.67953 21.1633 4.61596 21.1896 4.54967 21.1896H3.67527C3.57586 21.1896 3.48051 21.2291 3.41021
                            21.2994C3.33992 21.3697 3.30042 21.465 3.30042 21.5644C3.30042 21.6639 3.33992 21.7592 3.41021
                            21.8295C3.48051 21.8998 3.57586 21.9393 3.67527 21.9393H3.92517C4.1461 21.9389 4.35897 22.0222
                            4.52084 22.1726C4.68272 22.3229 4.78155 22.5291 4.79741 22.7494C4.81326 22.9698 4.74495 23.1879
                            4.60627 23.3599C4.46758 23.5319 4.26884 23.6449 4.05012 23.6761V23.9386C4.05012 23.9714 4.04366
                            24.0039 4.0311 24.0343C4.01854 24.0646 4.00013 24.0921 3.97692 24.1154C3.95371 24.1386 3.92616
                            24.157 3.89584 24.1695C3.86551 24.1821 3.83301 24.1886 3.80019 24.1886C3.76737 24.1886 3.73487
                            24.1821 3.70455 24.1695C3.67422 24.157 3.64667 24.1386 3.62346 24.1154C3.60025 24.0921 3.58184
                            24.0646 3.56928 24.0343C3.55672 24.0039 3.55026 23.9714 3.55026 23.9386V23.6885H3.0502C2.98392
                            23.6885 2.92035 23.6621 2.87348 23.6153C2.8266 23.5684 2.80027 23.5048 2.80027 23.4385C2.80027
                            23.3723 2.8266 23.3087 2.87348 23.2618C2.92035 23.2149 2.98392 23.1886 3.0502 23.1886H3.9246C4.02402
                            23.1886 4.11937 23.1491 4.18966 23.0788C4.25996 23.0085 4.29946 22.9132 4.29946 22.8138C4.29946
                            22.7143 4.25996 22.619 4.18966 22.5487C4.11937 22.4784 4.02402 22.4389 3.9246 22.4389L3.92486 22.439Z"
                      />
                      <path
                        d="M33.5422 11.9094H28.713C28.2861 11.9094 27.8766 12.079 27.5748 12.3809C27.2729 12.6828
                            27.1033 13.0922 27.1033 13.5191C27.1033 13.946 27.2729 14.3555 27.5748 14.6573C27.8766 14.9592
                            28.2861 15.1288 28.713 15.1288H29.4425L22.1641 21.8876L16.8004 17.8657C16.5022 17.6421 16.1355
                            17.529 15.7631 17.5456C15.3907 17.5622 15.0356 17.7076 14.7584 17.9569L6.70975 25.2006C6.55265
                            25.342 6.42493 25.513 6.33391 25.7038C6.24288 25.8945 6.19031 26.1014 6.17922 26.3125C6.16813
                            26.5236 6.19872 26.7348 6.26925 26.934C6.33978 27.1333 6.44887 27.3167 6.59028 27.4738C6.7317
                            27.6309 6.90268 27.7586 7.09345 27.8497C7.28423 27.9407 7.49106 27.9933 7.70215 28.0044C7.91324
                            28.0154 8.12444 27.9849 8.32371 27.9143C8.52297 27.8438 8.70639 27.7347 8.8635 27.5933L15.9279
                            21.2352L21.3089 25.2697C21.6105 25.4963 21.9822 25.6097 22.3589 25.59C22.7357 25.5703 23.0935
                            25.4188 23.3698 25.162L31.9325 17.211V18.3483C31.9325 18.7752 32.1021 19.1846 32.404 19.4865C32.7058
                             19.7884 33.1153 19.958 33.5422 19.958C33.9691 19.958 34.3785 19.7884 34.6804 19.4865C34.9823
                             19.1846 35.1519 18.7752 35.1519 18.3483V13.5192C35.1519 13.3078 35.1103 13.0985 35.0294 12.9031C34.9486
                             12.7078 34.83 12.5303 34.6805 12.3809C34.531 12.2314 34.3536 12.1128 34.1583 12.0319C33.963
                             11.951 33.7536 11.9094 33.5422 11.9094Z" stroke="white"
                      />
                    </svg>
                  </div>
                </div>

                <div>
                  <div class="text-base uppercase font-medium">annual cost savings</div>
                  <p
                    v-if="annualCostSaving && costSaving"
                    class="text-2xl font-semibold"
                  >{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(annualCostSavingTotal).replace('$', currencySymbol) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldSelect from '@/components/form/FormFieldSelect';
import FormFieldInput from '@/components/form/FormFieldInput';
import debounce from 'lodash.debounce';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import currencies from '../../currencies';
import {mapGetters, mapMutations} from 'vuex';
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: 'CostSaving',

  props: {
    options: {
      type: Array,
      default: () => ([])
    }
  },

  components: {
    FormFieldSelect,
    FormFieldInput
  },

  data: () => ({
    activePhase: {},
    selected: '',
    localOptions: [],
    costSaving: 0,
    annualCostSaving: 0,
    proAct360: 50,
    proAct: 15,
    price: 0,
    totalCostWithoutProact: 0,
    totalCostWithProact: 0,
    annualCostSavingMask: currencyMask,
    calculateReplacement: '',
    currency: 'USD',
    currencySymbol: '$',
  }),

  created() {
    this.localOptions = this.options;
    this.$set(this, 'activePhase', this.localOptions[0])
    this.selected = this.localOptions[0].id
  },

  mounted() {
    this.loadActiveCurrency()
    this.onCalculateReplacement()
  },

  methods: {
    ...mapMutations({
      setCostSavingCurrency: 'result/setCostSavingCurrency',
      setCostSavingCurrencySymbol: 'result/setCostSavingCurrencySymbol',
    }),

    onCalculateReplacement() {
      this.calculateReplacement = ''
      return this.$api.post('/cost-savings/calculate-replacement', {
        dietPhase: this.activePhase
      }).then(res => {
        this.$set(this, 'activePhase', res.data)
        this.onChangeIngredientPrice()
      }).catch((error) => {
        const { message } = error.response && error.response.data ? error.response.data : {}

        if (message) {
          this.calculateReplacement = message
        }
      })
    },

    loadActiveCurrency() {
      this.currency = this.$store.state.result.costSavingCurrency
      this.currencySymbol = this.$store.state.result.costSavingCurrencySymbol
      this.price = this.currency !== 'USD' ? 0 : (this.getSelectedProduct === 'proact360' ? this.proAct360 : this.proAct)
    },

    onSetPhase(id) {
      const activePhase = this.localOptions.find(el => {
        // eslint-disable-next-line eqeqeq
        return el.id == id
      })

      this.$set(this, 'activePhase', activePhase)

      this.onCalculateReplacement()
    },

    onSetCurrency(id) {
      if (currencies[id]) {
        this.currency = id
        this.currencySymbol = currencies[id].symbol_native

        this.price = 0
        this.onChangeIngredientPrice()

        this.setCostSavingCurrency(this.currency)
        this.setCostSavingCurrencySymbol(this.currencySymbol)
      }
    },

    onChangeIngredientPrice: debounce(function(e, ingredient) {
      /* eslint-disable no-invalid-this */
      this.changeAllPhases()

      this.$api.post('cost-savings/calculate-savings', {
        dietPhase: this.activePhase,
        proact360Price: this.price
      }).then(res => {
        this.costSaving = res.data.costSavings
        this.totalCostWithoutProact = res.data.totalCostWithoutProact
        this.totalCostWithProact = res.data.totalCostWithProact
        this.$store.commit('feed/onChangeCurrentPhase', this.activePhase)
      })
    }, 500),

    changeAllPhases() {
      const activePhase = JSON.parse(JSON.stringify(this.activePhase || {}))
      const currentIdx = this.localOptions.findIndex(el => el.id === activePhase.id)
      const ingredientsWithoutMandatory = activePhase.ingredients.filter((a) => !a.mandatory && !a.category)
      ingredientsWithoutMandatory.forEach(ingredient => {
        this.localOptions.forEach((phase, phaseKey) => {
          if (phaseKey === currentIdx) {
            return ''
          }

          const phaseIngredient = phase.ingredients.find(phaseIngredient => ingredient.unique_identifier === phaseIngredient.unique_identifier)

          if (phaseIngredient) {
            phaseIngredient.price = ingredient.price
          }
        })
      })
    }
  },

  watch: {
    getSelectedProduct: function(val) {
      this.price = this.currency !== 'USD' ? 0 : (val === 'proact360' ? this.proAct360 : this.proAct)
    }
  },

  computed: {
    ...mapGetters({
      getSelectedProduct: 'productSelect/getSelectedProduct',
    }),
    ingredientsWithPercentage() {
      const ingredients = this.activePhase.ingredients.filter(ingredient => Number(ingredient.percentage) || Number(ingredient.withProact360))
      const ingredientsWithoutMandatory = ingredients.filter((a) => !a.mandatory && !a.category)
      const ingredientsWithMandatory = ingredients.filter((a) => a.mandatory || a.category)
      return [...ingredientsWithoutMandatory, ...ingredientsWithMandatory];
    },
    annualCostSavingTotal() {
      if (typeof this.annualCostSaving !== 'number') {
        return Number(this.annualCostSaving.replaceAll(',', '')) * this.costSaving
      }

      return this.annualCostSaving * this.costSaving
    },
    currencies() {
      return Object.values(currencies);
    },
    withoutProact360Sum() {
      return Math.round(this.ingredientsWithPercentage.reduce((accum, current) => {
        return accum + (parseFloat(current.percentage) || 0)
      }, 0) * 100) / 100
    },
    withProact360Sum() {
      return Math.round(this.ingredientsWithPercentage.reduce((accum, current) => {
        return accum + (parseFloat(current.withProact360) || 0)
      }, 0) * 100) / 100
    }
  },
};

</script>

<style scoped>
</style>
