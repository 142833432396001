<template>
  <div class="grid-wrapper pb-14 rounded-lg overflow-hidden lg:overflow-visible bg-white">
    <ve-table
      rowKeyFieldName="rowkey"
      max-height="1500px"
      :fixed-header="true"
      :scroll-width="this.getTableWidth"
      :columns="tableData.columns"
      :table-data="tableData.tableData"
      :row-style-option="rowStyleOption"
      :cell-style-option="cellStyleOption"
      :border-around="false"
      :border-x="true"
      :border-y="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import 'vue-easytable/libs/theme-default/index.css';

export default {
  nutrient: 'GridTable',
  components: {
    VeTable
  },

  props: {
    tableData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    cellStyleOption: {
      exception: ['nutrient', 'mean', 'wMean', 'meanMatrix', 'meanContribution', 'wMean', 'wMeanMatrix', 'wMeanContribution'],

      headerCellClass: function(column) {
        const fieldName = column.column.field

        if (!this.exception.includes(fieldName)) {
          return 'phase'
        }

        return fieldName
      },

      bodyCellClass: function({ column }) {
        const fieldName = column.field

        if (!this.exception.includes(fieldName)) {
          return 'phase'
        }

        return fieldName
      }
    },

    rowStyleOption: {
      stripe: true,
    }
  }),

  computed: {
    getTableWidth() {
      const percentage = 100
      const phasesLength = this.tableData.columns.filter(obj => obj.field === 'phase').length

      if (window.innerWidth < 768) {
        return phasesLength >= 2 ? window.innerWidth * 2 : window.innerWidth
      }

      return phasesLength >= 4 ? `${percentage + (phasesLength * 5)}%` : percentage + '%'
    }
  }
};
</script>

<style scoped>
>>> .ve-table th[class*="ve-table"],
>>> .ve-table td[class*="ve-table"] {
  @apply p-1 lg:p-2.5 !important
}

>>> .ve-table thead th {
  @apply text-white border-transparent uppercase !important;
}

>>> .ve-table thead .nutrient,
>>> .ve-table thead .wMean {
  background-color: var(--color-semiMedium) !important;
}

>>> .ve-table thead .mean {
  background-color: var(--color-mediumLightest) !important;
}

>>> .ve-table thead tr:first-child th {
  @apply text-sm !important;
}

>>> .ve-table thead tr:last-child th,
>>> .ve-table tfoot td:first-child {
  @apply text-small lg:text-xs !important;
}

>>> .ve-table thead tr:first-child .phase:nth-child(odd) {
  background-color: var(--color-dark) !important;
}

>>> .ve-table thead tr:first-child .phase:nth-child(even) {
  background-color: var(--color-semiDark) !important;
}


>>> .ve-table thead tr:last-child th:nth-child(odd){
  background-color: var(--color-mediumLight) !important;
}

>>> .ve-table thead tr:last-child th:nth-child(even){
  background-color: var(--color-medium) !important;
}

>>> .ve-table tbody .nutrient,
>>> .ve-table tfoot .nutrient {
  color: var(--color-dark) !important;
}

>>> .ve-table tbody tr:not(:hover) .phase:nth-child(4n+2),
>>> .ve-table tbody tr:not(:hover) .phase:nth-child(4n+3),

>>> .ve-table tfoot tr .phase:nth-child(4n+2),
>>> .ve-table tfoot tr .phase:nth-child(4n+3) {
  @apply bg-grey-lightest !important;
}

>>> .ve-table tbody td,
>>> .ve-table tfoot td {
  color: var(--color-semiMedium) !important;
}
</style>
