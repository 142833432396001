<template>
  <modal
    :isOpen="isOpen"
    @close="onModalClose"
  >
    <template #title>
      <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
        <h3 class="sub-title text-2xl m-0 text-left">
          Export Matrix
          <small>Select the format of the exportable file.</small>
        </h3>
      </div>
    </template>

    <ul class="flex justify-between mt-2.5 mb-14 space-x-4 md:space-x-0">
      <li
        v-for="exportType in exportTypes"
        :key="exportType.type"
        class="cursor-pointer"
        :class="[activeExportType.type === exportType.type ? 'bg-blue-300 bg-opacity-50' : '', 'rounded-md  p-4 bg-white relative']"
        @click='onSetActiveExportType(exportType)'
      >
        <img :src="exportType.src" alt="">

        <div
          v-if="activeExportType.type === exportType.type"
          class="p-2 absolute rounded-full bg-brand-semiMedium text-white w-7 h-7 -right-3 -top-3 flex items-center"
        >
          <span class="icon w-full" v-html="icons.check"></span>
        </div>
      </li>
    </ul>

    <!-- <div class="pb-4 border-b border-grey-mediumLight mb-6 flex justify-between">
      <div class="text-grey-dark">
        <p>Export with properties?</p>
        <p class="text-xs">Include each phase's raw material inclusion and nutrient specifications.</p>
      </div>

      <label class="flex items-center cursor-pointer">
        <div class="relative">
          <input type="checkbox" v-model="exportWithProperties" class="hidden">

          <div class="wrapper block bg-white w-14 h-8 rounded-full border border-brand-semiMedium"></div>

          <div class="dot absolute left-1 top-1 bg-brand-semiMedium w-6 h-6 rounded-full transition"></div>
        </div>
      </label>
    </div> -->

    <template #button>
      <button class="btn-success" @click="onDownload">Download</button>
    </template>
  </modal>
</template>

<script>
/* eslint indent: 0 */
/* eslint no-unused-vars: 0 */
/* eslint prefer-const: 0 */
/* eslint quotes: 0 */
/* eslint no-var: 0 */
import Modal from '@/components/Modal';
import ModalMixin from '@/mixins/ModalMixin';
import api from '@/plugins/axios';
import axios from 'axios';

export default {
  name: 'Export',

  props: {
    resultData: {
      type: Array,
      default: () => ([])
    },

    activeDiet: {
      type: Object,
      default: () => ({})
    },

    isOpen: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Modal
  },

  mixins: [
    ModalMixin
  ],

  data: () => ({
    exportWithProperties: false,
    activeExportType: {
      type: 'csv'
    },
    exportTypes: [
      {
        type: 'csv',
        src: `${require('@/assets/images/export/csv.png')}`
      },
      {
        type: 'xlsx',
        src: `${require('@/assets/images/export/xlsx.png')}`
      },
      {
        type: 'pdf',
        src: `${require('@/assets/images/export/pdf.png')}`
      }
    ]
  }),

  methods: {
    onSetActiveExportType(type) {
      this.$set(this, 'activeExportType', type)
    },

    onDownload() {
      // NOTE: api call
      const data = {
        fileFormat: this.activeExportType.type,
        withProperties: this.exportWithProperties,
        matrix_data: this.resultData,
        properties: {}
      }

      axios
        .create({
          withCredentials: true,
          baseURL: api.defaults.baseURL
        }).request({
        url: `matrix/actions/export`,
        method: 'post',
        data,
        headers: {
          ...api.headers
        },
        responseType: 'blob'
      })
        .then((response) => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const blob = new Blob([response.data]);
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute(
              "download",
              `matrix.${this.activeExportType.type}`
            );
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === "undefined") {
              tempLink.setAttribute("target", "_blank");
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
          } else {
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data]),
              `matrix.${this.activeExportType.type}`
            );
          }
        });

      this.onModalClose()
    }
  }
};
</script>

<style scoped>
input:checked ~ .wrapper {
  @apply bg-brand-semiMedium
}

input:checked ~ .dot {
  transform: translateX(100%);
  @apply bg-white
}
</style>
