<template>
  <div :class="[validationClass, 'form-field']">
    <label class="block">
      <span :class="[size, 'relative', fieldLabel ? 'block' : 'flex']">
        <span
          v-if="fieldLabel || isResetValue"
          class="label block"
        >
          {{fieldLabel}}

          <span
            v-if="tooltip.length"
            class="w-4 tooltip-block"
            v-html="icons.note"
            v-tooltip="tooltip"
          >
          </span>

          <span
            v-if="isResetValue"
            title="Reset to default"
            class="reset-value"
            @click="$emit('reset-value')"
          ></span>
        </span>

        <input
          v-mask="maskInput"
          ref="input"
          :type="type"
          :class="[$slots.icon ? 'has-icon' : '', 'input']"
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          @click="onClick"
          @blur="onBlur"
          @input="onInput"
        />

        <span :class="className">
          <slot name="icon"></slot>
        </span>
      </span>
    </label>

    <p class="helper">
      <slot name="helper"></slot>
    </p>
  </div>
</template>

<script>
import FormFieldMixin from '@/mixins/FormFieldMixin';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: 'FormFieldInput',

  props: {
    type: {
      type: String,
      default: 'text'
    },

    placeholder: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'small'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isRemoveZero: {
      type: Boolean,
      default: false
    },

    isNeedFormatter: {
      type: Boolean,
      default: false
    },

    isResetValue: {
      type: Boolean,
      default: false
    },

    tooltip: {
      type: String,
      default: ''
    }
  },

  mixins: [
    FormFieldMixin
  ],

  data: () => ({
    val: '',
    mask: currencyMask
  }),

  methods: {
    onClick(e) {
      const target = e.target || e
      this.val = Number(target.value)

      if (this.val === 0 && (this.type === 'number' || this.isRemoveZero)) {
        // NOTICE: v-toggle directive, runs after click and blocks null deletion
        // because the parent component redraws and removes focus on input
        setTimeout(() => {
          target.value = ''
        }, 1);
      }
    },

    onInput(e) {
      this.val = e.target.value

      this.$emit('input', this.val)
    },

    onBlur(e) {
      const numberValue = Number(this.val)

      if (numberValue === 0 && (this.type === 'number' || this.isRemoveZero)) {
        e.target.value = numberValue
      }

      this.$emit('blur', e.target.value)
    }
  },

  computed: {
    maskInput() {
      return this.isNeedFormatter ? this.mask : ''
    },
    className() {
      let className = 'icon absolute m-0 '

      switch (this.size) {
        case 'small':
          className += ' right-3.5 '
          break

        case 'medium':
          className += ' right-3 lg:right-4 xl:right-5 '
          break

        case 'large':
          className += ' right-3 md:right-5 xl:right-7 '
          break
      }

      if (!this.fieldLabel) {
        className += ' top-1/2 transform -translate-y-1/2 '
        return className
      }

      switch (this.size) {
        case 'small':
          className += ' '
          break

        case 'medium':
          className += ' bottom-0 lg:bottom-1.5 '
          break

        case 'large':
          className += ' bottom-3.5 '
          break
      }

      return className
    }
  }
};
</script>

<style scoped>
.input {

}

.reset-value {
  cursor: pointer;
  background-image: url('~@/assets/images/icons/reset-value.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.tooltip-block {
  @apply text-semiBlack ml-2;
}

>>> .tooltip-block svg {
  width: 14px;
  height: 14px;
}

label >.flex .reset-value {
  margin-left: 0;
  margin-right: 10px;
}

label >.flex >.label {
  margin-bottom: 0;
}

.icon {
  @apply leading-loose;
  color: #9CA3AF;
}

.small .input {
  @apply py-2 px-3.5 lg:py-2 rounded-md
}

.medium .input {
  @apply py-2 px-3 lg:px-4 lg:py-3 xl:px-5 rounded-md
}

.large .input {
  @apply py-3 px-3 md:px-5 xl:py-4 xl:px-7 rounded-lg
}

.label {
  display: flex;
  align-items: center;
}

.small .label {
  @apply text-sm
}

.medium .label {
  @apply text-sm
}

.large .label {
  @apply text-sm
}

.input.has-icon {
  @apply pr-7 !important
}

</style>
