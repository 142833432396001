export default {
  props: {
    validationClass: {
      type: String | Object,
      default: () => ('')
    },
    value: {
      type: String | Number,
      default: ''
    },
    fieldLabel: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
  }
}
